import React from 'react';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';
import './JobNetCard.css';
import { Chip } from '@material-ui/core';
import JobNetTabs from './JobNetTabs/JobNetTabs';

export default function SimpleCard() {

  return (
    <Card className="JobNetCard">
      <h1 className="JobNetCard__ribbon">Job Net</h1>

      <CardContent>

        <div className="JobNetCard__tags">
          {['HTML', 'CSS', 'REACT', 'NODEJS'].map((tag, index) => {
            return <Chip
              className="JobNetCard__singleTag"
              size="small"
              variant="outlined"
              color="primary" key={index}
              label={tag} >
            </Chip>
          })}
        </div>

      </CardContent>

      <div className="JobNetTabs">
        <JobNetTabs></JobNetTabs>
      </div>

    </Card>
  );
}
