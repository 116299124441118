import { Avatar, Card } from '@material-ui/core'
import React from 'react';
import './Advertisement.css';

function Advertisement() {
    return (
        <Card className="Advertisement">
            
            <h4 className="Advertisement_name">Advertisement</h4>

            <div className="Advertisement_divider"></div>

            <div className="Advertisement_body">
            <Avatar className="Advertisement_image"/>

               
            </div>

        </Card>
    )
}

export default Advertisement
