import { Accordion, AccordionDetails, AccordionSummary, Card, Chip, Tooltip } from '@material-ui/core';
import { CommentOutlined, ExpandMore, FavoriteBorderOutlined, ShareOutlined } from '@material-ui/icons';
import React, { Component } from 'react';
import './LoginNetCard.css';

class LoginNetCard extends Component {

    render() {
        const netData = this.props.netData;

        let media = null;

        if (netData.mediaType === 'video') {
            media = (
                <div className="loginNetCard__video_wrapper">
                    <video className="loginNetCard__video" controls>
                        <source src={netData.media} />
                    </video>

                    {/* <MediaPlayer className="loginNetCard__video" /> */}

                </div>
            )
        }

        if (netData.mediaType === 'image') {
            media = (
                <div className="loginNetCard__image_wrapper">
                    <img className="loginNetCard__image" src={netData.media} alt="media_image">
                    </img>
                </div>
            )
        }


        return (
            <div>

                <h1 className="loginNetCard__ribbon">{netData.netType} Net</h1>

                <Card className="loginNetCard">

                    <div className="loginNetCard__profile_wrapper">
                        <img className="loginNetCard__profilePhoto" src="https://source.unsplash.com/100x100/?face" alt="Avatar" />
                        <div className="loginNetCard__nameAndDesignation">
                            <h2 className="loginNetCard__name">Aman Goyal</h2>
                            <Tooltip arrow className="loginNetCard__tooltip" title="Krishna Enginerring College" placement="right-start">
                                <h5 className="loginNetCard__designation">Student</h5>
                            </Tooltip>
                            <h5 className="loginNetCard__netWorth">Net Worth: 1000</h5>
                        </div>
                    </div>

                    <div className="loginNetCard__divider"></div>


                    <div className="loginNetCard__post">

                        <div className="loginNetCard__tags">
                            {netData.tags.map((tag, index) => {
                                return <Chip
                                    className="loginNetCard__singleTag"
                                    size="small"
                                    variant="outlined"
                                    color="primary" key={index}
                                    label={tag} ></Chip>
                            })}

                        </div>

                        <div className="loginNetCard__headingAndSubheading">
                            {netData.mediaType !== 'none' ?
                                <Accordion square className="loginNetCard__accordion">

                                    <AccordionSummary
                                        className="loginNetCard__accordionSummary"
                                        expandIcon={<ExpandMore />}
                                    >
                                        <p className="loginNetCard__accordionHeading">{netData.netTitle}</p>
                                    </AccordionSummary>

                                    <AccordionDetails className="loginNetCard__accordionDetails">
                                        <p className="loginNetCard__accordionSubheading">{netData.netDescription}</p>
                                    </AccordionDetails>
                                </Accordion>
                                :
                                <div>
                                    <p className="loginNetCard__heading">{netData.netTitle}</p>
                                    <p className="loginNetCard__subheading">{netData.netDescription}</p>
                                </div>
                            }

                            {/* Media will render here, if available */}
                            {media}

                        </div>

                        {!this.props.preview ? <div className="loginNetCard__lcsIcons">
                            <div className="loginNetCard__likeIcon">
                                <FavoriteBorderOutlined />
                                <span>{netData.likeCount} people like this</span>
                            </div>
                            <div className="loginNetCard__csIcons">

                                <div className="loginNetCard__commentIcon">
                                    <CommentOutlined />
                                    <span>{netData.commentCount}</span>
                                </div>

                                <div className="loginNetCard__shareIcon">
                                    <ShareOutlined />
                                    <span>{netData.shareCount}</span>
                                </div>
                            </div>
                        </div>
                            : null}
                    </div>

                </Card>
            </div>
        );
    }
}

export default LoginNetCard;