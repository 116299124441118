import React from 'react';
import './UserProfileCard.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function UserProfileCard() {
    return (
        <div className="UserProfile_profile-card">
            <div className="UserProfile_card-header">


            </div>
            <div className="UserProfile_pic">
                <img className="UserProfile_profileimage" src="https://source.unsplash.com/100x100/?face"
                    alt="Cover" />
            </div>
            <div className="UserProfile_name">Akshay Gupta</div>
            <div className="UserProfile_desc">Developer & Designer</div>
            <div className="UserProfile_sm">
                <FacebookIcon className="UserProfile_fbicon"></FacebookIcon>
                <TwitterIcon className="UserProfile_twittericon"></TwitterIcon>
                <GitHubIcon className="UserProfile_githubicon"></GitHubIcon>
                <LinkedInIcon className="UserProfile_linkedinicon"></LinkedInIcon>
            </div>
        </div>
    )
}

export default UserProfileCard
