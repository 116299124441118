// import * as actionTypes from '../actions/actionTypes';

const initialState = {
    currentUser: {
        "_id": {
            "$oid": "5f60a709f036647814cae448"
        },
        "profileType": "student",
        "coverPic": "https://source.unsplash.com/1920x370/?science,technology",
        "profilePic": "https://source.unsplash.com/225x300/?face",
        "username": 'amangoyal',
        'about': "I Don’t know how? But i believe that it is possible one day if i stay with my dream all time.",
        "fullName": "Aman Goyal",
        "password": "password",
        "dob": "1234",
        "email": "aman@gmail.com",
        "phone": "8851807342",
        "lastLogin": "1234",
        "userIntroList": { 'available': '3 hours', 'designation': 'MERN Developer', 'currentLocation': 'Delhi, India', 'home': 'Ghaziabad, Uttar Pradesh', 'hobbies': 'Coding, Listening Music' },
    }
}

const reducer = (state = initialState, action) => {

    return state;

};

export default reducer;