import React from 'react';
import './HomeContent.css';
import RecentCard from '../Profile/ProfileContent/RecentCard/RecentCard';
import Advertisement from '../Home/Advertisement/Advertisement';
import HomeNets from './HomeNets/HomeNets';

function homeContent({ nets }) {
    console.log(nets);
    return (
        <div className="homeContent">

            {/* ------------------ First Column --------------------------- */}
            <div className="homeContent__userprofilecard">
                <div className="homeContent__userProfileCardTopEmptySpace"></div>
            </div>

            <div className="homeContent__nets">
                {nets.map((net) => <HomeNets key={net.created} netData={net} />)}
            </div>
            <div className="homecontent__extreme_right">


                <div className="homeContent__recent">
                    <RecentCard />
                </div>
                <div className="homecontent__Advertisement">
                    <Advertisement />
                </div>
            </div>
        </div>

    )
}

export default homeContent
