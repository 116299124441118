import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ProgressBar from "../ProgressBar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './JobNetTabs.css';
import { Paper } from '@material-ui/core';

const testData = [
    { bgcolor: "#D21A22", skillName: "HTML", completed: 60 },
    { bgcolor: "#FDD539", skillName: "CSS", completed: 30 },
    { bgcolor: "#192A57", skillName: "JAVASCRIPT", completed: 53 },
];


function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        backgroundColor: '#fafafa'
    }
}));

export default function FullWidthTabs() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <Paper square className={classes.paper} >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="About" {...a11yProps(0)} />
                    <Tab label="Skills" {...a11yProps(1)} />
                    <Tab label="Experience" {...a11yProps(2)} />
                    <Tab label="Achievements" {...a11yProps(3)} />
                </Tabs>
            </Paper>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    {/* Skills starts here */}

                    <div className="JobNetCard_header">

                        <Typography variant="h4" component="h2">
                            About me
                        </Typography>
                    </div>

                    <Typography className={classes.pos} color="textSecondary">
                        Graphic Designer & Photographer
                     </Typography>

                    <Typography variant="body2" component="p">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate enim nostrum magnam, hic quos doloribus animi at rerum dolorum consequuntur, illo blanditiis dolores, accusamus eos. A eligendi esse porro nostrum.          <br />
                    </Typography>


                </TabPanel>

                <TabPanel value={value} index={1} dir={theme.direction}>
                    {/* Skills starts here */}

                    <div className="JobNetCard_ProgressBar">
                        {testData.map((item, idx) => (
                            <ProgressBar key={idx} bgcolor={item.bgcolor} skillName={item.skillName} completed={item.completed} />
                        ))}
                    </div>


                </TabPanel>


                {/* Skills ends here */}

                {/* Project starts here */}
                <TabPanel value={value} index={2} dir={theme.direction}>

                    <div className="JobNetCard_header">

                        <Typography variant="h5" component="h2">
                            My Projects
                        </Typography>
                    </div>


                    <ol>
                        <li>Lorem ipsum dolor sit amet.</li>
                        <li>Lorem ipsum dolor sit.</li>
                        <li>Lorem ipsum, dolor sit amet consectetur adipisicing.</li>
                    </ol>

                </TabPanel>
                {/* Project ends here */}
                {/* Achivements starts here */}
                <TabPanel value={value} index={3} dir={theme.direction}>

                    <div className="JobNetCard_header">

                        <Typography variant="h5" component="h2">
                            My Achievements
                                </Typography>
                    </div>


                    <ol>
                        <li>Lorem ipsum dolor sit amet.</li>
                        <li>Lorem ipsum dolor sit.</li>
                        <li>Lorem ipsum, dolor sit amet consectetur adipisicing.</li>
                        <li>Lorem ipsum, dolor sit.</li>
                        <li>Lorem ipsum, dolor sit amet consectetur adipisicing consectetur adipisicing.</li>

                    </ol>



                </TabPanel>
                {/* Achivements ends here */}
            </SwipeableViews >
        </div >
    );
}