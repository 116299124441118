import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, Select } from '@material-ui/core';
import Magnet from '../../assets/Gallery/magnet1.png'

import React, { Component } from 'react';
import instance from '../../axios';
import './CreateNetDialog.css'

import NetCard from '../Nets/NetCard/NetCard'
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { closeCreateNetDialog } from '../../store/actions/createNetDialog';
import EffortNetForm from './EffortNetForm/EffortNetForm';

const netTypes = [
    { name: 'Info Net', value: 'info' },
    { name: 'Review Net', value: 'review' },
    { name: 'Ask Net', value: 'ask' },
    { name: 'Effort Net', value: 'effort' }
]
class CreateNetDialog extends Component {

    state = {
        openDialog: false,
        netType: 'effort',

        openPreviewDialog: false,
        uploadingNet: false,
        netFormData: {
            netTitle: '',
            netDescription: '',
            netTags: [],
            file: [],
            mediaType: 'none',
        },

        completeNetData: null
    }

    //------------------------- All Handlers ---------------------------

    callbackFunction = (childData) => {
        this.setState({ netFormData: childData })
    };

    handleClickOpen = () => {
        this.setState({ openDialog: true });
    };

    handlePreviewDialogOpen = () => {

        const netData = this.state.netFormData;

        if (netData.netTitle !== '' &&
            netData.netDescription !== '' &&
            netData.netTags.length !== 0 &&
            ((netData.mediaType !== 'none' && netData.file.length !== 0) ||
                (netData.mediaType === 'none' && netData.file.length === 0)
            )
        ) {
            let completeNetData = {
                netTitle: netData.netTitle,
                username: 'amangoyal',
                tags: netData.netTags,
                netType: this.state.netType,
                netDescription: netData.netDescription,
                mediaType: netData.mediaType,
                media: (netData.mediaType !== 'none') ? URL.createObjectURL(netData.file[0]) : ''
            }
            this.setState({ openPreviewDialog: true, completeNetData: completeNetData })
        }
        else {
            alert("Please complete the net to preview it.");
        }
    }

    handleClose = () => {
        this.setState({ openDialog: false, openPreviewDialog: false });
    };

    handlePreviewClose = () => {
        this.setState({ openPreviewDialog: false })
    }

    uploadNet = async (e) => {
        e.preventDefault();

        const netData = this.state.netFormData;

        if (netData.netTitle !== '' &&
            netData.netDescription !== '' &&
            netData.netTags.length !== 0 &&
            ((netData.mediaType !== 'none' && netData.file.length !== 0) ||
                (netData.mediaType === 'none' && netData.file.length === 0)
            )
        ) {

            this.setState({ openPreviewDialog: false, disableAll: true })
            this.setState({ uploadingNet: true });
            let file = netData.file;
            try {
                const formData = new FormData();

                let completeNetData = {
                    netTitle: netData.netTitle,
                    username: 'amangoyal',
                    tags: netData.netTags,
                    netType: this.state.netType,
                    netDescription: netData.netDescription,
                    mediaType: netData.mediaType,
                }

                console.log(completeNetData);

                formData.append('file', file[0]);
                formData.append('netData', JSON.stringify(completeNetData));

                let res = await instance.post(`/create-net`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(res);
                this.setState({ uploadingNet: false, disableAll: false });
                // this.reset();
            } catch (error) {
                console.log(error);
                this.setState({ uploadingNet: false, disableAll: false })
            }
        }
        else {
            alert("Please complete the net to preview it.");
        }

    };

    // *************************************************
    render() {

        let createNetInputForm = null;

        if (this.state.netType === 'effort') {
            createNetInputForm = <EffortNetForm parentCallback={this.callbackFunction} ></EffortNetForm>
        }

        return (
            <div>

                <Dialog open={this.props.openDialog}
                    onClose={this.state.handleClose}
                    fullWidth={true}
                    maxWidth={'sm'}>

                    {this.state.uploadingNet ? <LinearProgress /> : null}


                    <DialogTitle onClose={this.handleClose} disableTypography>
                        <div className="createNetDialog__titleBox">
                            <div className="createNetDialog__title">
                                <p>Create Net</p>
                                <img className="createNetDialog__magnetIcon" src={Magnet} alt="magnet" />
                            </div>
                            <div>
                                <IconButton onClick={this.props.onCloseCreateNetDialog}>
                                    <Close />
                                </IconButton>
                            </div>
                        </div>
                    </DialogTitle>

                    <div className="ModalNet__divider"></div>

                    <DialogContent dividers>

                        <FormControl variant="outlined" className="createNetDialog__textFields">
                            <InputLabel>Choose Net Type</InputLabel>
                            <Select
                                // disabled={this.state.disableAll}
                                native
                                label="Choose Net Type"
                                value={this.state.netType}
                                onChange={(e) => this.setState({ netType: e.target.value })}
                            >
                                {netTypes.map((netType) => <option key={netType.value} value={netType.value}>{netType.name}</option>)}

                            </Select>
                        </FormControl>

                        {createNetInputForm}
                    </DialogContent>


                    <DialogActions>
                        <Button color="primary"
                            variant="outlined"
                            className="createNetDialog__previewButton"
                            disabled={this.state.uploadingNet}
                            onClick={this.handlePreviewDialogOpen}>
                            Preview
                          </Button>
                        <Button color="primary"
                            variant="contained"
                            className="createNetDialog__publishButton"
                            disabled={this.state.uploadingNet}
                            onClick={this.uploadNet}>
                            {this.state.uploadingNet ? 'Publishing please wait' : 'Publish'}
                        </Button>
                    </DialogActions>

                </Dialog>

                {/**************** Preview Dialog ***********************/}
                <Dialog
                    open={this.state.openPreviewDialog}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle>How your Net looks like?</DialogTitle>
                    <DialogContent>
                        <NetCard preview netData={this.state.completeNetData} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlePreviewClose} color="primary">
                            Close
                       </Button>
                        <Button color="primary"
                            disabled={this.state.uploadingNet}
                            onClick={this.uploadNet}>
                            {this.state.uploadingNet ? 'Uploading please wait' : 'Upload Net'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        openDialog: state.createNetDialog.openCreateDialogModal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseCreateNetDialog: () => dispatch(closeCreateNetDialog())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNetDialog);
