import React, { Component } from 'react';
import UserProfileCard from '../UserProfileCard/UserProfileCard';
// import NetCard from '../../Nets/NetCard/NetCard';
import './HomeNets.css';
import JobNetCard from '../../Nets/JobNet/JobNetCard';


class Homenets extends Component {

    render() {
        const netData = this.props.netData;
        console.log(netData);
        return (
            <div className="HomeNet">
                <div className="HomeNet_Left">
                    <UserProfileCard></UserProfileCard>
                </div>
                <div className="HomeNet_right">
                    {/* <NetCard netData={netData} /> */}
                    <JobNetCard />

                </div>


            </div>
        );
    }
}

export default Homenets;