import React, { Component } from 'react';
import './Profile.css';

import Header from '../../components/Header/Header';
import CoverAndProfile from '../../components/Profile/CoverAndProfile/CoverAndProfile';
import ProfileContent from '../../components/Profile/ProfileContent/ProfileContent';

class Profile extends Component {
 
    render() {
        return (
            <div>
                {/* Common toolbar for all pages */}
                <Header></Header>

                {/* This component contains cover and profile photo and About, Projects, Networkers */}
                <CoverAndProfile></CoverAndProfile>

                {/* This component contains all content below the profile photo */}
                <ProfileContent nets={this.props.nets}></ProfileContent>
            </div>
        )
    }
}

export default Profile