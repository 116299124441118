// Net Actions
export const CREATE_NET = "CREATE_NET";
export const DELETE_NET = "DELETE_NET";
export const EDIT_NET = "EDIT_NET";

export const SET_NETS = "SET_NETS";

// User Actions
export const USER = "USER";

//Create Net Dialog Actions
export const OPEN_CREATE_NET_DIALOG="OPEN_CREATE_NET_DIALOG";
export const CLOSE_CREATE_NET_DIALOG="CLOSE_CREATE_NET_DIALOG";
