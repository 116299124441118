import * as actionTypes from '../actions/actionTypes';

const initialState = {
    openCreateDialogModal: false,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.OPEN_CREATE_NET_DIALOG:
            return {
                openCreateDialogModal: true
            };

        case actionTypes.CLOSE_CREATE_NET_DIALOG:
            return {
                openCreateDialogModal: false
            };

        default: return state;
    }

};

export default reducer;