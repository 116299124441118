import React, { Component } from 'react'

class CountDownTimer extends Component {

  state = {
    count: 1
  }
  render() {
    const { count } = this.state
    return (
      <div>
        <p>OTP will expire in: <b>{count}</b> seconds</p>
      </div>
    )
  }
  // setInterval
  // clearInterval
  componentDidMount() {
    const { startCount } = this.props
    this.setState({
      count: startCount
    })
    this.doIntervalChange()
  }

  doIntervalChange = () => {
    this.myInterval = setInterval(() => {
      let prev = this.state.count - 1;
      if (prev === -1) {
        return;
      }
      this.setState({
        count: prev
      })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }
}

export default CountDownTimer