import { Accordion, AccordionDetails, AccordionSummary, Card, Chip } from '@material-ui/core';
import { CommentOutlined, ExpandMore, FavoriteBorderOutlined, ShareOutlined } from '@material-ui/icons';
import React, { Component } from 'react';
import NetMenu from './NetCardMenu'
import './NetCard.css';
// import MediaPlayer from '../../UI/MediaPlayer';

class NetCard extends Component {

    state = {
        open: false,
        anchorEl: null
    }

    handleCardMenuOpen = (e) => {
        this.setState({ open: true, anchorEl: e.currentTarget });
    }

    handleCardMenuClose = () => {
        this.setState({ open: false, anchorEl: false });
    };

    render() {
        const netData = this.props.netData;

        let media = null;

        if (netData.mediaType === 'video') {
            media = (
                <div className="NetCard__video_wrapper">
                    <video className="NetCard__video" controls>
                        <source src={netData.media} />
                    </video>

                    {/* <MediaPlayer className="loginNetCard__video" /> */}

                </div>
            )
        }

        if (netData.mediaType === 'image') {
            media = (
                <div className="NetCard__image_wrapper">
                    <img className="NetCard__image" src={netData.media} alt="media_image">
                    </img>
                </div>
            )
        }


        return (
            <div>

                <h1 className="NetCard__ribbon">{netData.netType} Net</h1>

                <Card className="NetCard">

                    <div className="Netcard_header">

                        <div className="NetCard__tags">
                            {netData.tags.map((tag, index) => {
                                return <Chip
                                    className="NetCard__singleTag"
                                    size="small"
                                    variant="outlined"
                                    color="primary" key={index}
                                    label={tag} ></Chip>
                            })}

                        </div>

                        {this.props.profile ? (<div className="NetCard_Hamburger">
                            <NetMenu netId={netData._id} className="NetCard_hamburgerMenu" />
                        </div>) : null}

                    </div>

                    <div className="NetCard__post">

                        <div className="NetCard__headingAndSubheading">
                            {netData.mediaType !== 'none' ?
                                <Accordion square className="NetCard__accordion">

                                    <AccordionSummary
                                        className="NetCard__accordionSummary"
                                        expandIcon={<ExpandMore />}
                                    >
                                        <p className="NetCard__accordionHeading">{netData.netTitle}</p>
                                    </AccordionSummary>

                                    <AccordionDetails className="NetCard__accordionDetails">
                                        <p className="NetCard__accordionSubheading">{netData.netDescription}</p>
                                    </AccordionDetails>
                                </Accordion>
                                :
                                <div>
                                    <p className="NetCard__heading">{netData.netTitle}</p>
                                    <p className="NetCard__subheading">{netData.netDescription}</p>
                                </div>
                            }


                            {media}



                        </div>

                        {!this.props.preview ? <div className="NetCard__lcsIcons">
                            <div className="NetCard__likeIcon">
                                <FavoriteBorderOutlined />
                                <span>{netData.likeCount} people like this</span>
                            </div>
                            <div className="NetCard__csIcons">

                                <div className="NetCard__commentIcon">
                                    <CommentOutlined />
                                    <span>{netData.commentCount}</span>
                                </div>

                                <div className="NetCard__shareIcon">
                                    <ShareOutlined />
                                    <span>{netData.shareCount}</span>
                                </div>
                            </div>
                        </div>
                            : null}
                    </div>
                </Card>
            </div>
        );
    }
}

export default NetCard;