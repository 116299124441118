import {
    Button, Card, CardContent, CardHeader, Checkbox, createMuiTheme, FormControl, Grid,
    IconButton, InputAdornment, InputLabel, MenuItem,
    Select, Snackbar, TextField
} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/styles';

import { Clear, Close, Done, Facebook, LoopOutlined } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React, { Component } from 'react'
import classes from './Login.module.css'

import Logo from '../../assets/Logo/logo-small.png'
import instance from '../../axios';
import LoginNetCard from '../../components/Login/LoginNetcard/LoginNetCard';
// import Facebook from '../../components/Login/Facebooklogin/Facebook';
import CountDownTimer from '../../components/Login/CountDownTimer/CountDownTimer';
import CountryCodes from '../../CountryCodes.json';
// import GoogleBtn from '../../components/Login/Googlelogin/GoogleBtn';
import { withRouter } from 'react-router';

import myTheme from '../../myTheme';
import { connect } from 'react-redux';
import * as netsAction from '../../store/actions'
import GoogleLogo from '../../components/UI/GoogleLogo';

const successTextFieldTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#388e3c'
        },
    },
});

class Login extends Component {

    state = {
        userId: '',
        otp: '',
        username: '',
        profileType: '',

        countryCode: '+91',
        termsAccepted: true,
        timer: 180,

        otpSent: true,
        otpVerified: true,

        openSnackbar: false,
        snackbarMessage: "",

        hideCountryCodeBox: true,

        isUserNameTaken: false,
        usernameLoading: false,

        pressedMentorButtonClass: '',
        pressedStudentButtonClass: '',
        pressedStartupButtonClass: '',
        pressedCorporateButtonClass: '',

        source: 'videtorrium',

    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChooseProfileButton = (e, buttonType) => {
        e.preventDefault();

        if (buttonType === 'student') {
            this.setState(
                {
                    pressedStudentButtonClass: classes.pressedButtonStateStudent,
                    pressedMentorButtonClass: '',
                    pressedStartupButtonClass: '',
                    pressedCorporateButtonClass: '',
                    profileType: 'student'
                }
            );
        }

        if (buttonType === 'corporate') {
            this.setState(
                {
                    pressedCorporateButtonClass: classes.pressedButtonStatecorporate,
                    pressedMentorButtonClass: '',
                    pressedStudentButtonClass: '',
                    pressedStartupButtonClass: '',
                    profileType: 'corporate'
                }
            )
        }

        if (buttonType === 'startup') {
            this.setState(
                {
                    pressedStartupButtonClass: classes.pressedButtonStatestartup,
                    pressedMentorButtonClass: '',
                    pressedStudentButtonClass: '',
                    pressedCorporateButtonClass: '',
                    profileType: 'startup'
                }
            )
        }

        if (buttonType === 'mentor') {
            this.setState(
                {
                    pressedMentorButtonClass: classes.pressedButtonStatementor,
                    pressedStudentButtonClass: '',
                    pressedStartupButtonClass: '',
                    pressedCorporateButtonClass: '',
                    profileType: 'mentor',

                }
            )
        }
    }

    handleUserIdChange = (e) => {

        let value = e.target.value;

        if (/([a-zA-Z])+([ -~])*/.test(value)) {
            this.setState({ hideCountryCodeBox: true });
        }
        else {
            this.setState({ hideCountryCodeBox: false });
        }

        if (value === "") {
            this.setState({ hideCountryCodeBox: true });
        }

        this.setState({ userId: e.target.value });

    }

    handleCreateUserName = (e) => {
        // e.persist()
        let value = e.target.value;

        if (value.length >= 6) {
            console.log(e.target.value);

            this.setState({ usernameLoading: true });
            instance.post('/get-username', { username: value })
                .then(res => {
                    console.log(res)
                    if (res.data.isAvailable) {
                        this.setState({ isUserNameTaken: false });
                    }
                    else {
                        this.setState({ isUserNameTaken: true });
                    }
                    this.setState({ usernameLoading: false });
                })
                .catch(err => {
                    this.setState({ snackbarMessage: "Error in getting usernames", Snackbar: true });
                    console.log(err);
                    this.setState({ usernameLoading: false });
                })
        }

        this.setState({ username: e.target.value })
    }

    handleRadioButtonChange = (event) => {
        this.setState({ profileType: event.target.value });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false });
    };

    handleCheckboxChange = () => {
        let value = this.state.termsAccepted;
        this.setState({ termsAccepted: !value })
    }

    sendOtp = async (e) => {
        e.preventDefault();

        try {
            let res = await instance.post('/auth', { emailOrPhone: this.state.userId });
            console.log(res);
            if (res.status === 201) {
                this.setState({ otpSent: true, openSnackbar: true, snackbarMessage: "OTP sent successfully" });
            }
        }
        catch (e) {
            if (e.response) {
                if (e.response.status === 500) {
                    this.setState({ openSnackbar: true, snackbarMessage: "Error in sending OTP" });
                } else
                    this.setState({ openSnackbar: true, snackbarMessage: "Error in sending OTP" });
            }
            console.log(e);
        }

    }

    goBackButton = (e) => {
        e.preventDefault();
        this.setState(
            {
                userId: '',
                otpSent: false,
                hideCountryCodeBox: true,
                otp: '',
                timer: 180
            }
        )
    }

    resendOtp = async (e) => {
        e.preventDefault();
        try {
            let res = await instance.post('/resend-otp', { emailOrPhone: this.state.userId })
            console.log(res);

            if (res.status === 201) {
                this.setState({ timer: 180, openSnackbar: true, snackbarMessage: " A new OTP has been Sent" });
            }
        } catch (e) {

            this.setState({ openSnackbar: true, snackbarMessage: "Failed to resesnd OTP" });
            console.log(e);
        }

    }

    verifyOtp = async (e) => {
        e.preventDefault();

        try {
            let res = await instance.post('/verify-otp', { otp: this.state.otp, emailOrPhone: this.state.userId, source: 'vid' })
            console.log(res);

            if (res.data.isRegistered) {
                this.props.history.push('/');
                this.setState({ openSnackbar: true, snackbarMessage: "OTP Verified" });
            }
            else {
                this.setState({ otpVerified: true, openSnackbar: true, snackbarMessage: "OTP Verified" });

            }

        } catch (e) {
            if (e.response.status === 401) {
                this.setState({ openSnackbar: true, snackbarMessage: "Invalid OTP" });
            }
            else if (e.response.status === 410) {
                this.setState({ openSnackbar: true, snackbarMessage: "OTP Expired" });
            }
            else
                this.setState({ openSnackbar: true, snackbarMessage: "Failed to check OTP" });
            console.log(e);
        }

    }


    lauchVidetorrium = (e) => {
        e.preventDefault();

        instance.post('/signup', {
            emailOrPhone: this.state.userId,
            username: this.state.username,
            profileType: this.state.profileType,
            source: this.state.source
        })
            .then(res => {
                console.log(res);
                this.props.history.push('/')
            })
            .catch(err => {
                console.log(err);
            })

    }


    render() {

        const userIdInputModal = (
            <div>
                <form className={classes.form} onSubmit={(e) => this.sendOtp(e)}>
                    <div className={classes.codeAndUserIdWrapper}>
                        <div hidden={this.state.hideCountryCodeBox} className={classes.form_codeField}>
                            <FormControl
                                variant="outlined"
                            >
                                <InputLabel >Code</InputLabel>
                                <Select
                                    hidden={true}
                                    value={this.state.countryCode}
                                    onChange={(e) => { this.setState({ countryCode: e.target.value }) }}
                                    label="Age"
                                >
                                    {CountryCodes.map((country) => {
                                        return <MenuItem key={country.dial_code} value={country.dial_code}>{country.code + " " + country.dial_code}</MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </div>
                        <TextField
                            variant="outlined"
                            onChange={this.handleUserIdChange}
                            value={this.state.userId}
                            className={classes.form_textField}
                            label="Phone number or Email"
                        ></TextField>


                    </div>

                    <div className={classes.termsAndConditions}>
                        <Checkbox
                            checked={this.state.termsAccepted}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            onChange={this.handleCheckboxChange}
                        />
                        <span className={classes.termsAndConditionsText}>I have read & accepted the <span className={classes.termsOfUse}>terms of use</span></span>
                    </div>

                    <Button
                        disabled={!this.state.termsAccepted}
                        variant="contained" color="primary"
                        type="submit" className={classes.form_button}>Send OTP</Button>
                </form>

            </div>
        );

        const otpVerificationModal = (
            <div>
                <form className={classes.otpForm} onSubmit={(e) => this.verifyOtp(e)}>
                    <div className={classes.otpForm_heading_wrapper}>
                        <p className={classes.otpForm_heading}>
                            We have sent a verification code to <b>{this.state.userId}</b>.
                        </p>
                    </div>
                    <TextField
                        variant="outlined"
                        onChange={(e) => this.setState({ otp: e.target.value })}
                        value={this.state.otp}
                        className={classes.otpForm_textField}
                        placeholder="Enter OTP"
                    ></TextField>
                    <div className={classes.otpForm_resendAndBackButton}>
                        <Button color="primary" onClick={(e) => this.goBackButton(e)}>{'<< Back'}</Button>
                        <Button color="primary" onClick={(e) => this.resendOtp(e)}>Resend OTP</Button>
                    </div>
                    <Button
                        variant="contained" color="primary"
                        type="submit" className={classes.otpForm_submitButton}>Verify OTP</Button>

                    <div className={classes.countDownTimer}>
                        <CountDownTimer startCount={this.state.timer} />
                    </div>

                </form>
            </div>
        );

        let usernameTextFieldIcon = null;
        if (this.state.username.length > 5) {

            if (this.state.usernameLoading) {
                usernameTextFieldIcon = <LoopOutlined />;
            }

            if (this.state.isUserNameTaken) {
                usernameTextFieldIcon = <Clear color="error" />;
            }
            else {
                usernameTextFieldIcon = <Done color="primary" />;
            }

        }

        const createUserNameModal = (
            <div>
                <form className={classes.createUsername__form} onSubmit={this.lauchVidetorrium} >

                    <ThemeProvider theme={this.state.username.length >= 6 ? successTextFieldTheme : myTheme}>
                        <TextField
                            variant="outlined"
                            defaultValue="Success"
                            error={this.state.username.length >= 6 ? this.state.isUserNameTaken : false}
                            className={classes.createUsername__textField}
                            onChange={this.handleCreateUserName}
                            value={this.state.username}
                            helperText={this.state.username.length >= 6 ? this.state.isUserNameTaken ? "Username Unavailable" : 'Username Available' : 'Username should be greater than 5 characters.'}
                            label="Create Username"
                            // className={clsx(classes.margin, classes.textField)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">@</InputAdornment>,
                                endAdornment:
                                    <InputAdornment position="end">
                                        {usernameTextFieldIcon}
                                    </InputAdornment>

                            }}
                        />
                    </ThemeProvider>

                    <div className={classes.createUsername__profileTypeButtonsGrid}>
                        <div className={classes.createUsername__headingWrapper}>
                            <p>Choose Profile Type:</p>
                        </div>
                        <Grid container spacing={1} justify="space-around" alignItems="center" className={classes.createUsername__upperGrid}>
                            <Grid item xs >
                                <button
                                    className={`${classes.createUsername__studentprofileButton} ${this.state.pressedStudentButtonClass}`}
                                    onClick={(e) => this.handleChooseProfileButton(e, 'student')}
                                >

                                    Student &nbsp; {this.state.profileType === "student" ? <CheckCircleOutlineIcon /> : null}
                                </button>
                            </Grid>
                            <Grid item xs>
                                <button
                                    className={`${classes.createUsername__startupprofileButton} ${this.state.pressedStartupButtonClass}`}
                                    onClick={(e) => this.handleChooseProfileButton(e, 'startup')}
                                >
                                    Startup &nbsp; {this.state.profileType === "startup" ? <CheckCircleOutlineIcon /> : null}
                                </button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around" alignItems="center" className={classes.createUsername__lowerGrid}>
                            <Grid item xs>
                                <button
                                    className={`${classes.createUsername__corporateprofileButton} ${this.state.pressedCorporateButtonClass}`}
                                    onClick={(e) => this.handleChooseProfileButton(e, 'corporate')}
                                >
                                    Corporate &nbsp; {this.state.profileType === "corporate" ? <CheckCircleOutlineIcon /> : null}

                                </button>
                            </Grid>
                            <Grid item xs>
                                <button
                                    className={`${classes.createUsername__mentorprofileButton} ${this.state.pressedMentorButtonClass}`}
                                    onClick={(e) => this.handleChooseProfileButton(e, 'mentor')}
                                >
                                    Mentor &nbsp; {this.state.profileType === "mentor" ? <CheckCircleOutlineIcon /> : null}

                                </button>
                            </Grid>
                        </Grid>

                    </div>

                    <Button
                        variant="contained" color="primary"
                        type="submit"
                        className={classes.createUsername__submitButton}
                    >
                        Launch Videtorrium
                    </Button>
                </form>
            </div>
        )

        // inputForm holds and render diffrent modals dependeing on the condition 
        let inputForm = userIdInputModal;

        if (this.state.otpSent) {
            inputForm = otpVerificationModal
        }

        if (this.state.otpVerified) {
            inputForm = createUserNameModal;
        }

        // Return Function
        return (

            <div className="login">


                <Grid container className={classes.login}>
                    <Grid item xs={12} sm={12} md={5}>
                        <div className={classes.login_left} >
                            {this.props.nets.map(netData => <LoginNetCard key={netData._id} netData={netData} />)}
                        </div>
                    </Grid>


                    <Grid item xs={12} sm={12} md={7}>
                        <div className={classes.login_right} >
                            <div className={classes.login_right} >

                                <div className={classes.toolbar}>

                                    <div className={classes.toolbar_image_container}>
                                        <img className={classes.logo} src={Logo} alt="Videtorrium"></img>
                                    </div>


                                    <div className={classes.toolbar_text}>
                                        <h1 >We are <span className={classes.toolbar_text_change_content}></span> </h1>
                                    </div>

                                </div>


                                <div className={classes.auth_input}>
                                    <div className={classes.socialMediaIcon}>
                                        <div className={classes.google_icon_wrapper}>
                                            <div className={classes.google_icon_box} >
                                                <GoogleLogo />
                                            </div>
                                            <span className={classes.icon_text}>Continue with Google</span>
                                        </div>
                                        <div className={classes.facebook_icon_wrapper}>
                                            <div className={classes.facebook_icon_box} >
                                                <Facebook />
                                            </div>
                                            <span className={classes.icon_text}>Continue with Facebook</span>
                                        </div>
                                    </div>
                                    <Card className={classes.form_card}>
                                        <CardHeader
                                            className={classes.form_card_header}
                                            title="Login / Signup"
                                        />
                                        <CardContent className={classes.form_card_content}>
                                            {inputForm}
                                        </CardContent>

                                    </Card>
                                </div>

                            </div>
                        </div>
                    </Grid>
                </Grid >


                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.openSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    message={this.state.snackbarMessage}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                                <Close fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />

            </div >
        )
    }
}


const mapStateToProps = (state) => {
    return {
        nets: state.myNets.nets
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchNets: () => dispatch(netsAction.fetchNets())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
