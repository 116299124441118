import React, { Component } from 'react'

import Login from './Views/Login/Login'
import Home from './Views/Home/Home'
import Profile from './Views/Profile/Profile'
import MyProfile from './Views/MyProfile/MyProfile'
import { Switch, Route } from 'react-router-dom';
// import instance from './axios';
import * as netsAction from './store/actions/';
import { connect } from 'react-redux';
import CreateNetDialog from './components/CreateNetModal/CreateNetDialog'
class App extends Component {

  // state = {
  //   nets: []
  // }

  componentDidMount() {
    this.props.onFetchNets();
  }


  render() {

    return (
      <div className="app">

        <Switch>
          <Route path="/login" component={Login}></Route>
          <Route path="/profile" >
            <Profile nets={this.props.nets} />
          </Route>
          <Route path="/my-profile" component={MyProfile}></Route>

          <Route path='/' exact >
            <Home nets={this.props.nets} />
          </Route>
        </Switch>

        <CreateNetDialog />
        
      </div>
    )

  }

}

const mapStateToProps = (state) => {
  return {
    nets: state.myNets.nets
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchNets: () => dispatch(netsAction.fetchNets())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);