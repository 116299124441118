import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
    status: {
        danger: '#e53e3e',
      },
    palette: {
        primary: {
            main: '#D21A22',
            contrastText: '#fff',
        },
        secondary: { 
            light: '#0066ff',
            main: '#0044ff',
            contrastText: '#ffcc00',
        },
        mentor: {
            backgroundColor: '#192A57',
            color: '#fff',
        },
        corporate: {
            backgroundColor: '#EFBC1A',
            color: '#000',
        },
        startup: {
            backgroundColor: '#FDD539',
            color: '#000',
        },
        success: { 
            main: '#D21A22',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Raleway, sans-serif',
      },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },

});

