import { Grid, IconButton } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { connect } from 'react-redux';

import './CoverAndProfile.css';


function CoverAndProfile(props) {
    return (
        <Grid container direction="column" className="coverAndProfile">

            <div className="coverAndProfile__coverPhotoWrapper">
                <img
                    className="coverAndProfile__coverPhoto"
                    src={props.userData.currentUser.coverPic}
                    alt="Cover"
                />
            </div>

            <div className="coverAndProfile__belowCoverPhoto">

                <div className="coverAndProfile__profilePhotoWrapper">
                    <img
                        className="coverAndProfile__profilePhoto"
                        src={props.userData.currentUser.profilePic}
                        alt="profile"
                    />
                </div>

                <div className="coverAndProfile__blankProfilePhotoWrapper">
                </div>

                <div className="coverAndProfile__profileMenu">

                    <h4>About</h4>
                    <h4>Projects</h4>
                    <h4>Networkers</h4>

                </div>

                <div className="coverAndProfile__editIcon">
                    <IconButton>
                        <MenuIcon />
                    </IconButton>

                </div>
            </div>

        </Grid>
    )
}


const mapStateToProps = (state) => {
    return {
        userData: state.currentUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverAndProfile);

