import * as actionTypes from './actionTypes';

export const openCreateNetDialog = () => {
    return {
        type: actionTypes.OPEN_CREATE_NET_DIALOG,
    }
}

export const closeCreateNetDialog = () => {
    return {
        type: actionTypes.CLOSE_CREATE_NET_DIALOG,
    }
}