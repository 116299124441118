import { Card } from '@material-ui/core';
import { HomeOutlined, LocationOnOutlined, WorkOutline, HourglassEmpty, FavoriteBorderOutlined } from '@material-ui/icons'
import React from 'react';
import './UserDescriptionCard.css';

import { connect } from 'react-redux'

function UserDescriptionCard(props) {

    const userData = props.userData.currentUser;
    console.log(userData);

    const userIntroList =
        [
            { icon: <HourglassEmpty color="primary" />, text: userData.userIntroList.available },
            { icon: <WorkOutline color="primary" />, text: userData.userIntroList.designation },
            { icon: <HomeOutlined color="primary" />, text: userData.userIntroList.home },
            { icon: <LocationOnOutlined color="primary" />, text: userData.userIntroList.currentLocation },
            { icon: <FavoriteBorderOutlined color="primary" />, text: userData.userIntroList.hobbies },
        ]

    return (
        <Card className="userDescriptionCard">

            <h4 className="userDescriptionCard__name">{userData.fullName}</h4>

            <div className="userDescriptionCard__divider"></div>

            <h4 className="userDescriptionCard__username">@{userData.username}</h4>

            <div className="userDescriptionCard__divider"></div>

            <div className="userDescriptionCard__body">
                <p className="userDescriptionCard__about">I Don’t know how? But i believe that it is possible one day if i stay with my dream all time</p>

                <div className="userDescriptionCard__introList">
                    {userIntroList.map((el, index) => {
                        return <div key={index}
                            className="userDescriptionCard__introListItem">
                            {el.icon}  <span>{el.text} </span>
                        </div>
                    })}
                </div>

            </div>

        </Card>
    )
}


const mapStateToProps = (state) => {
    return {
        userData: state.currentUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDescriptionCard);
