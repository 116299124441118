import React, { Component } from 'react';
import './MyProfile.css';
import Header from '../../components/Header/Header';
import MyProfileCoverAndProfile from '../../components/MyProfile/MyProfileCoverAndProfile/MyProfileCoverAndProfile';
import MyProfileContent from '../../components/MyProfile/MyProfileContent';

class MyProfile extends Component {

    render() {
        return (
            <div>
                {/* Common toolbar for all pages */}
                <Header></Header>

                {/* This component contains cover and profile photo and About, Projects, Networkers */}
                <MyProfileCoverAndProfile></MyProfileCoverAndProfile>

                <MyProfileContent></MyProfileContent>
            </div>
        )
    }
}

export default MyProfile