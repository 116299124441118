import { Avatar, Card } from '@material-ui/core'
import React from 'react';
import './RecentCard.css'

function Recent() {
    return (
        <div>
            <Card className="recentCard">
                <div className="recentCard__headingWrapper">
                    <h3 className="recentCard__heading">Recent Notifications</h3>
                </div>

                <div className="recentCard__divider"></div>

                {[1, 2, 3, 4, 5].map(news => {
                    return <div key={news} className="recentCard__newsSingleItem">
                        <Avatar className="recentCard__NewsleftIcon" />
                        <div className="recentCard__newsTextWrapper">
                            <p className="recentCard__newsHeading">Any one can join with us if you want</p>
                            <p className="recentCard__newsTime">5 min ago</p>
                        </div>
                    </div>
                })}

            </Card>
        </div>
    )
}

export default Recent
