import { Grid, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import React from 'react';

import './MyProfileCoverAndProfile.css';


function MyProfileCoverAndProfile() {
    return (
        <Grid container direction="column" className="coverAndProfile">

            <div className="coverAndProfile__coverPhotoWrapper">
                <img
                    className="coverAndProfile__coverPhoto"
                    src="https://source.unsplash.com/1920x370/?science,technology"
                    alt="Cover"
                />
            </div>

            <div className="coverAndProfile__belowCoverPhoto">

                <div className="coverAndProfile__profilePhotoWrapper">
                    <img
                        className="coverAndProfile__profilePhoto"
                        src="https://source.unsplash.com/225x300/?face"
                        alt="profile"
                    />
                </div>

                <div className="coverAndProfile__blankProfilePhotoWrapper">
                </div>

                <div className="coverAndProfile__profileMenu">

                    <h4 className="active">Nets</h4>
                    <h4>About</h4>
                    <h4>Projects</h4>
                    <h4>Networkers</h4>
                    <h4>More</h4>

                </div>
                <div className="coverAndProfile__editIcon">
                    <IconButton>
                        <MenuIcon />
                    </IconButton>
                </div>
            </div>



            <div className="coverAndProfile__socialmenu">
                <div className="coverAndProfile__blankProfilePhotoWrapper2">
                </div>
                <div className="coverAndProfile__About">
                    <h3>About</h3>
                </div>
                <div className="coverAndProfile__SocialIcon">
                    <h4>Facebook</h4>
                    <FacebookIcon />
                </div>
                <div className="coverAndProfile__SocialIcon">
                    <h4>Twitter</h4>
                    <TwitterIcon />
                </div>
                <div className="coverAndProfile__SocialIcon">
                    <h4>google</h4>
                    <GTranslateIcon />
                </div>
                <div className="coverAndProfile__SocialIcon">
                    <h4>Pinterest</h4>
                    <PinterestIcon />
                </div>
                <div className="coverAndProfile__editIcon2">
                    <IconButton>
                        <MenuIcon />
                    </IconButton>

                </div>



            </div>

        </Grid>
    )
}

export default MyProfileCoverAndProfile
