import { Card } from '@material-ui/core';
import React from 'react';
import './MyProfileDescription.css';

function MyProfileDescription() {
    return (
        <Card className="MyProfileDescription">

            
            <button className="MyProfileDescription__aboutme">Something About me</button>
            <div className="MyProfileDescription__divider"></div>

            <button className="MyProfileDescription__working">Working Zone</button>

            <div className="MyProfileDescription__divider"></div>
            <button className="MyProfileDescription__education">Education Qualification</button>

            <div className="MyProfileDescription__divider"></div>
            <button className="MyProfileDescription__work">Work and Education</button>

            <div className="MyProfileDescription__divider"></div>
            <button className="MyProfileDescription__friends">Friends and Family</button>

            <div className="MyProfileDescription__divider"></div>
            <button className="MyProfileDescription__contact">Contact Details</button>
        </Card>
    )
}

export default MyProfileDescription
