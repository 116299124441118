import * as actionTypes from '../actions/actionTypes';
const initialState = {
    nets: [],
}

const reducer = (state = initialState, action) => {

    console.log(action)

    switch (action.type) {
        case actionTypes.SET_NETS:
            return {
                nets: action.nets.reverse()
            };

        case actionTypes.DELETE_NET:
            let nets = state.nets.filter((net) => net._id !== action.netId);

            return {
                nets: nets
            }

        default: return state;
    }

};

export default reducer;