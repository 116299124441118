import React from 'react';
import './MyProfileContent.css'
import MyProfileDescription from './MyProfileDescription/MyProfileDescription';


function MyProfileContent() {
    return (
        <div className="MyProfileContent">

            {/* ------------------ First Column --------------------------- */}
            <div className="MyProfileContent__userDescription">
                <div className="MyProfileContent__userDescriptionTopEmptySpace"></div>
                <MyProfileDescription></MyProfileDescription>
            </div>
        </div>
    )
}

export default MyProfileContent
