// import instance from '../../axios';
import * as actionTypes from './actionTypes';

export const deleteNet = (netId) => {
    return {
        type: actionTypes.DELETE_NET,
        netId: netId
    }
}

export const editNet = (netId) => {
    return {
        type: actionTypes.DELETE_NET,
        id: netId
    }
}

export const setNets = (nets) => {
    return {
        type: actionTypes.SET_NETS,
        nets: nets
    }
}

export const fetchNets = () => {
    return dispatch => {
        // instance.get('/get-usernets')
        //     .then(res => {
        //         dispatch(setNets(res.data))
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         alert("error in fetching nets");
        //     })

        dispatch(setNets([
            {
                shares: [],
                likes: [],
                tags: [
                    'REACT',
                    'MATERIAL_UI',
                    'PALETTE'
                ],
                comments: [],
                _id: '5f7b33734fb6030f14b851b1',
                netTitle: 'Palette',
                username: 'amangoyal',
                netType: 'job',
                netDescription: 'A color intention is a mapping of a palette color to a given intention within your application. ',
                mediaType: 'none',
                media: '',
                likeCount: '0',
                commentCount: '0',
                shareCount: '0',
                created: '2020-10-05T14:53:39.362Z',
                __v: 0
            },
            {
                shares: [],
                likes: [],
                tags: [
                    'GITHUB',
                    'KNOWLEDGE'
                ],
                comments: [],
                _id: '5f7b178b4fb6030f14b851b0',
                netTitle: 'What is Github?',
                username: 'amangoyal',
                netType: 'ask',
                netDescription: 'GitHub, Inc. is an American multinational corporation that provides hosting for software development and version control using Git. It offers the distributed version control and source code management functionality of Git, plus its own features.',
                mediaType: 'none',
                media: '',
                likeCount: '0',
                commentCount: '0',
                shareCount: '0',
                created: '2020-10-05T12:54:36.000Z',
                __v: 0
            },
            {
                shares: [],
                likes: [],
                tags: [
                    'VIDEO',
                    'TEST',
                    'EFFORT_NET'
                ],
                comments: [],
                _id: '5f75da647e3e7a3d909519b2',
                netTitle: 'This is effort net with video',
                username: 'amangoyal',
                netType: 'effort',
                netDescription: 'This is effort net with video Description',
                mediaType: 'video',
                media: 'https://vid-afzal.s3.ap-south-1.amazonaws.com/NetsMedia/amangoyal1601559139475.mp4',
                likeCount: '0',
                commentCount: '0',
                shareCount: '0',
                created: '2020-10-01T13:32:20.116Z',
                __v: 0
            },
            {
                shares: [],
                likes: [],
                tags: [
                    'IMAGE',
                    'TEST',
                    'ASK_NET'
                ],
                comments: [
                    '5f7898f4ec8a8f3014acd493',
                    '5f789a3ded29da1828706383'
                ],
                _id: '5f75d9987e3e7a3d909519b1',
                netTitle: 'This is ask net with image',
                username: 'amangoyal',
                netType: 'ask',
                netDescription: 'This is ask net Description with image',
                mediaType: 'image',
                media: 'https://vid-afzal.s3.amazonaws.com/NetsMedia/amangoyal1601558935032.jpg',
                likeCount: '0',
                commentCount: '0',
                shareCount: '0',
                created: '2020-10-01T13:28:56.536Z',
                __v: 2
            }
        ]))
    }
}