import { Videocam } from '@material-ui/icons';
import React from 'react';
import './ProfileContent.css'
import UserDescriptionCard from './UserDescriptionCard/UserDescriptionCard';
import NetCard from '../../Nets/NetCard/NetCard';
import RecentCard from './RecentCard/RecentCard';

function ProfileContent({ nets }) {
    return (
        <div className="profileContent">

            {/* ------------------ First Column --------------------------- */}
            <div className="profileContent__userDescription">
                <div className="profileContent__userDescriptionTopEmptySpace"></div>
                <UserDescriptionCard></UserDescriptionCard>
            </div>

            {/* ------------------ Second Column --------------------------- */}
            <div className="profileContent__nets">
                <div className="profileContent__createNetBox">

                    <div className="profileContent__createNetButton">
                        <div className="profileContent__createNetButton_text">
                            <p>Create Net</p>
                        </div>
                        <Videocam className="profileContent__createNetButton_videoIcon" />
                    </div>
                </div>
                {nets.map((net,index) => <NetCard key={index} profile netData={net} />)}

            </div>

            {/* ------------------ Third Column --------------------------- */}
            <div className="profileContent__recent">
                <RecentCard />
            </div>

        </div>
    )
}

export default ProfileContent
