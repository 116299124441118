import React, { Component } from 'react';
import Header from '../../components/Header/Header';
// import UserProfileCard from '../../components/Home/UserProfileCard/UserProfileCard';
import HomeContent from '../../components/Home/HomeContent';

export default class Home extends Component {
    render() {
        return (

            <div>
                <Header></Header>
                <HomeContent nets={this.props.nets}></HomeContent>
            </div>
        )
    }
}

