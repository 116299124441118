import { Box, Chip, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, TextField } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { Component } from 'react';

class EffortNetForm extends Component {

    state = {
        netTitle: '',
        netDescription: '',
        netTags: [],
        newTagName: '',
        file: [],
        mediaType: 'none',
    }

    // ********************** All Handlers ***********************
    handleUploadFile = (files) => {
        if (!this.state.disableAll) {
            console.log(files);
            this.setState({ file: files })
        }

        this.sendData({
            ...this.state,
            file: files
        });
    }

    handleAddNewTag = (event) => {
        event.preventDefault();
        this.setState({
            netTags: this.state.netTags.concat(this.state.newTagName),
            newTagName: ''
        });

        this.sendData({
            ...this.state,
            netTags: this.state.netTags.concat(this.state.newTagName)
        });
    }

    handleMediaTypeChange = (event) => {
        this.setState({ mediaType: event.target.value })

        this.sendData({
            ...this.state,
            mediaType: event.target.value
        });
    }

    handleTagDelete = (event, tag) => {
        event.preventDefault();
        let tags = this.state.netTags.filter((netTag) => netTag !== tag)
        this.setState({ netTags: tags });

        this.sendData({
            ...this.state,
            netTags: tags
        });
    }


    reset = () => {
        this.setState(
            {
                openDialog: false,
                netTitle: '',
                netDescription: '',
                netTags: [],
                newTagName: '',
                file: [],
                isUploading: false,
                mediaType: 'none',
                uploadingNet: false
            }
        )
    }

    handleTitleNameChange = (e) => {
        this.setState({ netTitle: e.target.value })

        this.sendData({
            ...this.state,
            netTitle: e.target.value
        });
    };

    handleNetDescriptionChange = (e) => {
        this.setState({ netDescription: e.target.value });

        this.sendData({
            ...this.state,
            netDescription: e.target.value
        });
    };

    sendData = (data) => {
        this.props.parentCallback(data)

    };

    // *****************************************************************

    render() {

        let dropAreaText = "Drag and drop a Video or click"
        let acceptedFiles = ['video/*'];
        let filesLimit = 1;

        if (this.state.mediaType === 'image') {
            dropAreaText = "Drag and drop a Image and audio (optional) or click";
            acceptedFiles = ['image/*'];
            filesLimit = 4
        }

        return (
            <div>
                <TextField
                    disabled={this.state.disableAll}
                    label="Give a Title to your Net" className="createNetDialog__textFields"
                    variant="outlined" fullWidth value={this.state.netTitle}
                    onChange={this.handleTitleNameChange}
                />

                <TextField className="createNetDialog__textFields createNetDialog__descriptionField"
                    label="Describe your Net"
                    multiline
                    disabled={this.state.disableAll}
                    fullWidth
                    value={this.state.netDescription}
                    onChange={this.handleNetDescriptionChange}
                    variant="outlined"
                >
                </TextField>

                <FormControl disabled={this.state.file.length !== 0 || this.state.disableAll}
                    className="createNetDialog__mediaTypeForm">
                    <FormLabel component="legend">Upload Net Media</FormLabel>
                    <RadioGroup row name="mediaType" value={this.state.mediaType} onChange={this.handleMediaTypeChange}>
                        <FormControlLabel value="video" control={<Radio />} label="Video" />
                        <FormControlLabel value="image" control={<Radio />} label="Image and audio (optional)" />
                        <FormControlLabel value="none" control={<Radio />} label="None" />
                    </RadioGroup>
                </FormControl>

                {this.state.mediaType !== 'none' ?
                    <DropzoneArea

                        clearOnUnmount
                        acceptedFiles={acceptedFiles}
                        filesLimit={filesLimit}
                        maxFileSize={100000000}
                        showFileNamesInPreview
                        showFileNames
                        value={this.state.file}
                        onChange={this.handleUploadFile}
                        dropzoneText={dropAreaText}
                    ></DropzoneArea>
                    : null
                }

                <Box display="flex" flexDirection='column' className="createNetDialog__tagsContainer">

                    <Paper variant="outlined" className="createNetDialog__showTagsContainer">

                        {this.state.netTags.length === 0 ? <p>No Tags yet!</p> : null}

                        {this.state.netTags.map((tag) => {

                            return (

                                <Chip
                                    disabled={this.state.disableAll}
                                    key={tag}
                                    label={tag}
                                    color="primary"
                                    onDelete={(event) => this.handleTagDelete(event, tag)}
                                    className="createNetDialog__tagChip"
                                />
                            );
                        })}
                    </Paper>


                    <form onSubmit={this.handleAddNewTag} >
                        <Box display="flex" className="createNetDialog__addTagContainer">
                            <TextField
                                disabled={this.state.disableAll}
                                value={this.state.newTagName}
                                onChange={(e) => this.setState({ newTagName: (e.target.value.toUpperCase()) })}
                                className="createNetDialog__addTagInput"
                                size="small"
                                variant="outlined"
                                label="Add a Tag and press enter"
                            ></TextField>
                        </Box>
                    </form>
                </Box>
            </div>
        )
    }
}

export default EffortNetForm;